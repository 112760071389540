import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { SketchSection } from "../components/sections/SketchSection"

const SketchesPage = () => {
  return (
    <Layout isProjectPage>
      <SEO />

      <SketchSection />
    </Layout>
  )
}

export default SketchesPage
