import React from "react"

import { ImageGrid } from "../ImageGrid.tsx"
import { Hero } from "../Hero.tsx"
import { TextColumns } from "../TextColumns.tsx"
import { StyledFlex } from "../StyledFlex.tsx"
import { Image } from "../Image.tsx"

const sketchImage = number => `sketch-${number}.jpg`

export const SketchSection = () => {
  return (
    <>
      <Hero>
        Sketches
        <Image src={"sketch-thumbnail"} />
      </Hero>

      <ImageGrid>
        <Image src={sketchImage(1)} />
        <Image src={sketchImage(2)} />
        <Image src={sketchImage(3)} />
        <Image src={sketchImage(4)} />
      </ImageGrid>

      <ImageGrid>
        <Image src={sketchImage(5)} />
        <Image src={sketchImage(6)} />
      </ImageGrid>

      <ImageGrid>
        <Image src={sketchImage(7)} />
        <Image src={sketchImage(8)} />
        <Image src={sketchImage(9)} />
        <Image src={sketchImage(10)} />
      </ImageGrid>
    </>
  )
}
